import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import Card from "../../components/Card/Card";
import Layout from "../../components/Layout";
import promotionImg1 from "../../assets/images/promotions-step-1.svg";
import promotionImg2 from "../../assets/images/promotions-step-2.svg";
import promotionImg3 from "../../assets/images/promotions-step-3.svg";
import horizontalArrow from "../../assets/images/horizontal-arrow.svg";
import verticalArrow from "../../assets/images/vertical-arrow.svg";
import { getDiscounts, getSingleDiscount, setDiscountId } from "../../reducers/Discounts/actions";
import { getMapData } from '../../reducers/Map/actions'
import { connect } from "react-redux";
import { graphql } from "gatsby"
import { urlDescription } from '../../utils/pageDescription'
import "./styles.scss";

const DiscountsPage = ({
  discounts,
  page,
  getDiscounts,
  getSingleDiscount,
  setDiscountId,
  getMapData,
  data
}) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (typeof window !== "undefined" && window.innerWidth < 1051) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const discountsData = data.allStrapiDiscounts.edges

  useEffect(() => {
    getDiscounts({ page });
    getMapData()
  }, [getDiscounts, getMapData, page]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="discounts-page-wrapper">
        <div className="discounts-page-container">
          <div className="discounts-page-title">
            <h1 className='h2 font-weight-bold text-white'>
              Ваучери за строителни материали
            </h1>
            <p>
              Свалете ваучер и спестете от строителството на Вашата къща!
            </p>
          </div>
          <Row className="discounts-row">
            {!!discountsData &&
              discountsData.map((d) => {
                return (
                  <Col key={d.node.id} md={6} lg={6} className="my-4 px-4">
                    <Card
                      cardClass="discounts-horizontal-card"
                      imageClass="discounts-horizontal-card-img"
                      cardBodyClass="discounts-card-body"
                      btnText="СВАЛЕТЕ ВАУЧЕР"
                      btnClass="discounts-card-btn"
                      title={d.node.title}
                      desc={d.node.short_description}
                      image={d.node.product_image.localFile}
                      imageObjectFit='contain'
                      isGatsbyImage
                      // image={`${process.env.GATSBY_XELLA_BACKEND}${d.image}`}
                      url={`/discount-details/${d.node.id}`}
                      discounts
                      onClick={() => {
                        getSingleDiscount({ id: d.id })
                        setDiscountId(d.id)
                      }}
                    />
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>

      <div className="discounts-steps-container px-3">
        <div className="mt-3 discounts-page-info">
          <h2 className='font-weight-bold'>
            Как да се възползвате?
          </h2>
          <p>
            Толкова е лесно, като едно, две, три!
          </p>
        </div>
        <Row className="pb-2 mb-5 discounts-info-row">
          <Col className='discounts-info-row__col'>
            <h5 className='font-weight-bold'>Стъпка 1</h5>
            <img alt="step-1" src={promotionImg1} />
            <p className="discounts-info-desc">
              Свалете ваучера!
            </p>
          </Col>
          <Col sm={isMobile ? 12 : ''} className='discounts-info-row__arrow-col mb-xs-4 mb-md-4'>
            <img
              alt="arrow"
              className="discounts-arrow"
              src={isMobile ? verticalArrow : horizontalArrow}
            />
          </Col>
          <Col className='discounts-info-row__col'>
            <h5 className='font-weight-bold'>Стъпка 2</h5>
            <img alt="step-2" src={promotionImg2} />
            <p className="discounts-info-desc">
              Представете ваучера при поръчката на материали!
            </p>
          </Col>
          <Col sm={isMobile ? 12 : ''} className='discounts-info-row__arrow-col mb-xs-4 mb-md-4'>
            <img
              alt="arrow"
              className="discounts-arrow"
              src={isMobile ? verticalArrow : horizontalArrow}
            />
          </Col>
          <Col className='discounts-info-row__col'>
            <h5 className='font-weight-bold'>Стъпка 3</h5>
            <img alt="step-3" src={promotionImg3} />
            <p className="discounts-info-desc">
              Вземете материалите и спестете пари!
            </p>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    discounts: state.discounts.data,
    page: state.discounts.current_page,
  };
};

const mapDispatchToProps = {
  getDiscounts,
  getSingleDiscount,
  setDiscountId,
  getMapData
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountsPage);

export const query = graphql`
  {
    allStrapiDiscounts {
      edges {
        node {
          id: strapiId
          title
          short_description
          product_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: AUTO)
              }
            }
          }
        }
      }
    }
  }
`

